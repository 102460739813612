import React, { useEffect, useState } from 'react';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Alert, Box, Button, Container, Stack } from '@mui/material';
import MainHeading from 'app/components/Heading/MainHeading';
import Content from 'app/components/content';
import DataContainer, {
	SAVE_TEMPLATE_BUTTON_TEXT,
} from 'app/containers/Patients/PatientDetails/components/Container';
import {
	sendingDataToTemplateOrToUpdateUser,
	useUpdatingFarPerType,
	useUpdatingRequirementsPatient,
	useUpdatingRestrictions,
} from 'app/containers/Patients/PatientDetails/utils';
import { PatientRepoSaga } from 'app/containers/Patients/redux/saga';
import {
	actions,
	reducer as reducerPatient,
	sliceKey as sliceKeyPatient,
} from 'app/containers/Patients/redux/slice';
import { actions as templatesActions } from 'app/containers/Templates/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { Description } from '../Descriptions';
import { References, initValue } from '../References';
import { TemplatesRepoSaga } from '../redux/saga';
import {
	selectGetTemplateData,
	selectUpdateTemplateError,
} from '../redux/selectors';
import {
	reducer as reducerTemplates,
	sliceKey as sliceKeyTemplates,
} from '../redux/slice';
import { TemplatesTypeEnum } from '../redux/types';
import Header from 'app/components/Header';

const TemplatesDetails = () => {
	useInjectReducer({ key: sliceKeyTemplates, reducer: reducerTemplates });
	useInjectSaga({ key: sliceKeyTemplates, saga: TemplatesRepoSaga });
	useInjectReducer({ key: sliceKeyPatient, reducer: reducerPatient });
	useInjectSaga({ key: sliceKeyPatient, saga: PatientRepoSaga });
	const [macroNutrientsDetails, setMacroNutrientsDetails] = useState<any[]>([]);
	const location = useLocation();
	const { templateType } = location?.state || {};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const useGetTemplateData = useSelector(selectGetTemplateData);
	const useUpdateTemplateError = useSelector(selectUpdateTemplateError);
	const [showTemplateReferences, setShowTemplateReferences] = useState(false);
	const [templateReferences, setTemplateReferences] = useState([initValue]);
	const [templateDescriptions, setTemplateDescriptions] = useState(
		useGetTemplateData?.templateData?.templateDescriptions,
	);

	useEffect(() => {
		setTemplateReferences(
			useGetTemplateData?.templateData?.templateReferences || [initValue],
		);
	}, [useGetTemplateData?.templateData?.templateReferences]);
	useEffect(() => {
		setTemplateDescriptions(
			useGetTemplateData?.templateData?.templateDescriptions,
		);
	}, [useGetTemplateData?.templateData?.templateDescriptions]);

	const gatTemplateCallback = e => {};
	useEffect(() => {
		if (location.pathname) {
			dispatch(
				templatesActions.getTemplateById({
					_id: location.pathname.split('/')[2],
					toUpdateState: false,
					gatTemplateCallback: gatTemplateCallback,
				}),
			);
		}
		dispatch(actions.fetchNutrientAndVitaminDetails(null));
	}, [dispatch, location.pathname]);
	const {
		userVitamins,
		setUserVitamins,
		nutrients,
		setNutrients,
		vitaminMinerals,
		setVitaminMinerals,
		practitionerExcluded,
		setPractitionerExcluded,
		practitionerIncluded,
		setPractitionerIncluded,
		practitionerIngredientsWarning,
		setPractitionerIngredientsWarning,
	} = useUpdatingRequirementsPatient(
		setMacroNutrientsDetails,
		useGetTemplateData?.templateData,
	);
	const { restrictions, setRestrictions } = useUpdatingRestrictions(
		useGetTemplateData?.templateData,
	);
	const handleSaveTemplate = templateName => {
		const [userNutrients, userVitamins, updateDataForPatientDetail] =
			sendingDataToTemplateOrToUpdateUser(
				nutrients,
				vitaminMinerals,
				practitionerExcluded,
				practitionerIncluded,
				practitionerIngredientsWarning,
				restrictions,
				useGetTemplateData?.templateData,
			);
		const _templateReferences = templateReferences?.filter(item => item);
		Object.assign(updateDataForPatientDetail, {
			templateReferences: _templateReferences,
			templateDescriptions: templateDescriptions,
		});
		dispatch(
			templatesActions.updateTemplate({
				templateData: updateDataForPatientDetail,
				templateName: useGetTemplateData?.templateName,
				_id: useGetTemplateData?._id,
				callback: handleCancelButton,
			}),
		);
	};
	useUpdatingFarPerType(macroNutrientsDetails, nutrients, setNutrients);
	const handleCancelButton = () => {
		dispatch(templatesActions.setUpdateTemplateError(''));
		const state = {
			templateType,
		};
		navigate('/templates', { state });
	};
	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Container maxWidth={'xl'}>
					<Box
						sx={{
							padding: '20px 0px 0px 30px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'flex-start',
						}}
					>
						<MainHeading title={useGetTemplateData?.templateName} />
						{(templateType === TemplatesTypeEnum.ANYDISH ||
							templateType === TemplatesTypeEnum.CLINIC) && (
							<Button onClick={() => setShowTemplateReferences(p => !p)}>
								<ModeEditIcon />
							</Button>
						)}
						{(templateType === TemplatesTypeEnum.ANYDISH ||
							templateType === TemplatesTypeEnum.CLINIC) &&
							showTemplateReferences && (
								<Box
									sx={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Description
										title={'Template descriptions'}
										templateData={templateDescriptions}
										setTemplateDate={setTemplateDescriptions}
									/>
									<References
										title={'Template references'}
										templateData={templateReferences}
										setTemplateDate={setTemplateReferences}
									/>
								</Box>
							)}
					</Box>
					<DataContainer
						nutrients={nutrients}
						setNutrients={setNutrients}
						vitaminMinerals={vitaminMinerals}
						setVitaminMinerals={setVitaminMinerals}
						setPractitionerExcluded={setPractitionerExcluded}
						practitionerExcluded={practitionerExcluded}
						setPractitionerIncluded={setPractitionerIncluded}
						practitionerIncluded={practitionerIncluded}
						setPractitionerIngredientsWarning={
							setPractitionerIngredientsWarning
						}
						practitionerIngredientsWarning={practitionerIngredientsWarning}
						restrictions={restrictions}
						setRestrictions={setRestrictions}
						userVitamins={userVitamins}
						setUserVitamins={setUserVitamins}
						handleSave={handleSaveTemplate}
						handleCancelButton={handleCancelButton}
						saveButtonText={SAVE_TEMPLATE_BUTTON_TEXT}
					/>
				</Container>
				{useUpdateTemplateError && (
					<Box>
						<Stack spacing={2} sx={{ width: '100%' }}>
							<Alert
								onClose={() => {
									dispatch(templatesActions.setUpdateTemplateError(''));
								}}
								severity="error"
								sx={{ width: '100%' }}
							>
								{useUpdateTemplateError}
							</Alert>
						</Stack>
					</Box>
				)}
			</Content>
		</Box>
	);
};

export default TemplatesDetails;
