import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Container, Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { Logo } from 'app/components/Logo';
import HelpButton from 'app/containers/Help/containers/HelpButton';
import {
	selectSearchTerm,
	selectUserSidebarOpen,
} from 'app/containers/Patients/redux/selector';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { REACT_APP_MODE } from 'utils/constants';
import { actions } from '../../containers/Patients/redux/slice';
import { useStyles } from './styles';
import WatchTraining from '../topbar/WatchTraining';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';
export const LOGO_STYLE = { width: 93, height: 25 };

interface TopbarProps {
	showWatchTraining?: boolean;
}

const Header = ({ showWatchTraining }: TopbarProps) => {
	const styles = useStyles();
	const isSidebarOpen = useSelector(selectUserSidebarOpen);
	const searchTerm = useSelector(selectSearchTerm);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isPractitionerUser = useIsPractitionerUser();

	return (
		<Box className={clsx(styles.root)}>
			<Container maxWidth={'xl'} className={clsx(styles.wrapper)}>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={9} md={7}>
						<Box className={clsx([styles.logo_search_wrapper])}>
							<Button
								onClick={() => navigate('/')}
								className={clsx([styles.logo])}
							>
								<Logo width={LOGO_STYLE.width} height={LOGO_STYLE.height} />
							</Button>
							<Box className={clsx(styles.search_box_parent)}>
								{REACT_APP_MODE !== 'production' && isPractitionerUser && (
									<HelpButton />
								)}
							</Box>
						</Box>
					</Grid>
					<Grid item xs={3} md={5} justifyContent="flex-end">
						<Box className={clsx(styles.nav_icons__container)}>
							<Box className={clsx(styles.nav_icons_user_container)}>
								{showWatchTraining && <WatchTraining />}
								{/* <IconButton><Badge onClick={() => dispatch(actions.UserToggleMenu())} badgeContent={100} color="secondary"><PersonIcon color="primary" /></Badge></IconButton> */}
								<IconButton
									className={clsx(styles.toggle)}
									onClick={() => dispatch(actions.UserToggleMenu())}
								>
									{isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
								</IconButton>
							</Box>

							{/* <Box className={clsx(styles.nav_icons_user_container)}>
                  <Badge badgeContent={100} color="secondary">
                    <PersonIcon color="primary" />
                  </Badge>
                  <IconButton onClick={() => dispatch(actions.toggleMenu())}>
                    {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Box> */}
						</Box>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Header;
