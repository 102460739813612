import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutline from './icons/PersonOutline';
import {
	Box,
	Button,
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { ClinicPopup } from 'app/containers/Clinics/ClinicPopup';
import { PatientRepoSaga } from 'app/containers/Patients/redux/saga';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { selectSubMenuOpen } from '../../containers/Patients/redux/selector';
import { actions as templatesActions } from 'app/containers/Templates/redux/slice';
import {
	actions,
	reducer,
	sliceKey,
} from '../../containers/Patients/redux/slice';
import {
	actions as actionsClinic,
	sliceKey as sliceKeyClinic,
	reducer as reducerClinic,
} from '../../containers/Clinics/redux/slice';
import { ClinicRepoSaga } from '../../containers/Clinics/redux/saga';
import { selectActiveClinic } from '../../containers/Clinics/redux/selector';
import {
	actions as actionsPractitioner,
	sliceKey as sliceKeyPractitioner,
	reducer as reducerPractitioner,
} from '../../containers/Practitioner/redux/slice';
import { PractitionerRepoSaga } from '../../containers/Practitioner/redux/saga';
import { useStyles } from './styles';
import { hasChildren } from './utils';
import { JsIconSideIcon } from 'assets/images';
import {
	selectSelectedPractitioner,
	selectSideMenuPractitionersList,
} from 'app/containers/Practitioner/redux/selector';
import { selectUserType } from 'app/containers/Auth/selectors';
import { TemplatesTypeEnum } from 'app/containers/Templates/redux/types';
const SideBar = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	useInjectReducer({ key: sliceKeyPractitioner, reducer: reducerPractitioner });
	useInjectSaga({ key: sliceKeyPractitioner, saga: PractitionerRepoSaga });
	const selectedClinic = useSelector(selectActiveClinic);
	const SideMenuPractitionersList = useSelector(
		selectSideMenuPractitionersList,
	);
	const loginUserType = useSelector(selectUserType);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [clinicList, setclinicList] = useState<Array<any>>([]);
	function showAddClinicPopup() {
		dispatch(actionsClinic.toggleAddClinicPopup());
	}

	const classes = useStyles();
	useEffect(() => {
		setclinicList(
			SideMenuPractitionersList.map(item => {
				if (selectedClinic == item._id) {
					return {
						icon: <JsIconSideIcon />,
						title: item.fullName,
						link: '/clinics',
						id: item._id,
						is_virtual: item.is_virtual,
						level: 1,
						items: item.practitioner.map(itempractitioner => {
							return {
								title: itempractitioner.fullName,
								icon: <PersonOutline />,
								link: '/patients',
								id: itempractitioner._id,
								level: 3,
							};
						}),
					};
				} else {
					return {
						icon: <JsIconSideIcon />,
						title: item.fullName,
						link: '/clinics',
						id: item._id,
						is_virtual: item.is_virtual,
						level: 1,
						items: item.practitioner.map(itempractitioner => {
							return {
								title: itempractitioner.fullName,
								icon: <PersonOutline />,
								link: '/patients',
								id: itempractitioner._id,
								level: 3,
							};
						}),
					};
				}
			}),
		);
		if (
			selectedClinic &&
			selectedClinic.length == 0 &&
			SideMenuPractitionersList.length > 0
		) {
			dispatch(actionsClinic.setActiveClinc(SideMenuPractitionersList[0]._id));
			dispatch(
				actionsClinic.setActiveClincName(SideMenuPractitionersList[0].fullName),
			);
			dispatch(
				actionsClinic.setActiveClincNameIsVirtual(
					SideMenuPractitionersList[0].is_virtual ?? 0,
				),
			);
		}
		return () => {};
	}, [SideMenuPractitionersList]);

	useEffect(() => {
		dispatch(actionsPractitioner.searchPractitionersClinic(''));

		return () => {};
	}, []);

	if (loginUserType === '2' || loginUserType === 'PRACTITIONER') {
		return <></>;
	}
	const goToPromoCodes = async () => {
		navigate('/promo-codes');
	};
	const goToManagementFaq = async () => {
		navigate('/faq');
	};
	const goToAnydishTemplates = async () => {
		const state = {
			templateType: TemplatesTypeEnum.ANYDISH,
		};
		navigate('/templates', { state });
	};
	return (
		<Box
			id="shiv"
			sx={{ minHeight: '100vh' }}
			className={clsx(classes.activesidebar)}
		>
			<Box className={clsx(classes.inner)}>
				<Button
					onClick={() => goToAnydishTemplates()}
					className={clsx(classes.sidebarBtn)}
					variant="contained"
					color="primary"
				>
					anydish templates
				</Button>
				<Button
					onClick={() => goToManagementFaq()}
					className={clsx(classes.sidebarBtn)}
					variant="contained"
					color="primary"
				>
					Management FAQ
				</Button>
				<Button
					onClick={() => goToPromoCodes()}
					className={clsx(classes.sidebarBtn)}
					variant="contained"
					color="primary"
				>
					Management promo codes
				</Button>
				<Button
					onClick={showAddClinicPopup}
					className={clsx(classes.sidebarBtn)}
					variant="contained"
					color="primary"
				>
					Add a new clinic
				</Button>
				<Box className={clsx(classes.clinicList)}>
					{clinicList.map((item, key) => (
						<MenuItem key={key} item={item} />
					))}
				</Box>
			</Box>
			<ClinicPopup />
		</Box>
	);
};

export default SideBar;

const MenuItem = ({ item }) => {
	const Component = hasChildren(item) ? MultiLevel : SingleLevel;
	return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
	const dispatch = useDispatch();
	const styles = useStyles();
	const handleClick = (id: string, title: string, is_virtual?: number) => {
		if (item.level === 1) {
			dispatch(actionsClinic.setActiveClinc(id));
			dispatch(actionsClinic.setActiveClincName(title));
			dispatch(actionsClinic.setActiveClincNameIsVirtual(is_virtual ?? 0));
		} else {
			dispatch(actionsPractitioner.setActivePractitioner(id));
			dispatch(actionsPractitioner.setActivePractitionerName(title));
			localStorage.setItem('practitionerId', id);
			localStorage.setItem('practitionerName', title);
			dispatch(actions.searchPatients(''));
			dispatch(actions.searchPatientsArchived(''));
		}
	};
	const selectedClinic = useSelector(selectActiveClinic);
	const selectedPractitioner = useSelector(selectSelectedPractitioner);
	const showLink = Object.keys(item).includes('items')
		? '/practitioner'
		: '/patients';
	return (
		<Link
			to={item.link}
			className={clsx([
				styles.link,
				item.level == 3
					? selectedPractitioner == item.id && styles.dropLink
					: selectedClinic == item.id && styles.dropLink,
			])}
		>
			<ListItem
				button
				style={{
					marginLeft: item.level == 3 ? 20 : 0,
					width: item.level == 3 ? '92%' : '100%',
				}}
				onClick={() => {
					handleClick(item.id, item.title, item.is_virtual);
				}}
			>
				<ListItemIcon>{item.icon}</ListItemIcon>
				<ListItemText primary={item.title} />
				<Link to={showLink}>
					<ExpandLessIcon
						style={{ transform: 'rotate(0.25turn)', color: '#fff' }}
					/>
				</Link>
			</ListItem>
		</Link>
	);
};

const MultiLevel = ({ item }) => {
	const dispatch = useDispatch();
	const { items: children, id } = item;
	const styles = useStyles();
	const selectedClinic = useSelector(selectActiveClinic);
	const isSubMenuOpen = useSelector(selectSubMenuOpen);
	const handleClick = (id: string, title: string, is_virtual?: number) => {
		dispatch(actions.toggleSubMenu());
		dispatch(actionsClinic.setActiveClinc(id));
		dispatch(actionsClinic.setActiveClincName(title));
		dispatch(actionsClinic.setActiveClincNameIsVirtual(is_virtual ?? 0));
	};
	const handleClinicClick = (
		id: string,
		title: string,
		is_virtual?: number,
	) => {
		dispatch(actionsClinic.setActiveClinc(id));
		dispatch(actionsClinic.setActiveClincName(title));
		dispatch(actionsClinic.setActiveClincNameIsVirtual(is_virtual ?? 0));
	};

	return (
		<React.Fragment>
			<Link
				to={item.link}
				className={clsx([
					styles.link,
					selectedClinic == item.id && styles.dropLink,
				])}
			>
				<ListItem
					button
					onClick={() => {
						handleClinicClick(item.id, item.title, item.is_virtual);
					}}
				>
					<ListItemIcon>{item.icon}</ListItemIcon>
					<ListItemText primary={item.title} />
					{isSubMenuOpen && selectedClinic == item.id ? (
						<Link to={'/practitioner'}>
							<ExpandLessIcon
								style={{ color: '#fff' }}
								onClick={() => handleClick(item.id, item.title)}
							/>
						</Link>
					) : (
						<Link to={'/practitioner'}>
							<ExpandMoreIcon
								style={{ color: '#fff' }}
								onClick={() =>
									handleClick(item.id, item.title, item.is_virtual)
								}
							/>
						</Link>
					)}
				</ListItem>
			</Link>
			<Collapse
				in={isSubMenuOpen && selectedClinic == item.id}
				// in={isSubMenuOpen}
				unmountOnExit
			>
				<List component="div" disablePadding>
					{children.map((child, key) => (
						<MenuItem key={key} item={child} />
					))}
				</List>
			</Collapse>
		</React.Fragment>
	);
};
